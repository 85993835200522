html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
}

.terminal {
    background-color: #363636;
    color: #00ff5a;
    font-family: Monospace, "monospace";
    white-space: pre;
    height: 100%;
    font-size: 16px;
    overflow: auto;
    position: relative;
}

.terminal .history p {
    margin: 0;
    padding: 0;
    white-space: pre
}

.terminal .history p span {
    color: grey;
    font-size: 13px;
    padding-right: 8px;
}

.terminal input {
    font-family: Monospace, "monospace";
    background-color: #363636;
    border: 0;
    outline: none;
    color: #00ff5a;
    font-size: 16px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.loading {
    position: absolute;
}